// extracted by mini-css-extract-plugin
export var articlesButtonPosition = "blogList-module--articlesButtonPosition--31db9";
export var articlesItem = "blogList-module--articlesItem--09431";
export var articlesPosition = "blogList-module--articlesPosition--0509b";
export var blogAll = "blogList-module--blogAll--1ce5c";
export var featuredImageFrame = "blogList-module--featuredImageFrame--40ab9";
export var headerPosition = "blogList-module--headerPosition--df9c8";
export var headerWrapper = "blogList-module--headerWrapper--08f7f";
export var itemAnchor = "blogList-module--itemAnchor--d8250";
export var itemCopy = "blogList-module--itemCopy--f9a88";
export var itemHeader = "blogList-module--itemHeader--b6d7c";
export var itemWrapper = "blogList-module--itemWrapper--5bab3";
export var pagenation = "blogList-module--pagenation--89a8b";
export var sectionArticles = "blogList-module--sectionArticles--e3d9e";
export var wrap = "blogList-module--wrap--b6659";