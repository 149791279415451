import React from "react"
import { Link , useStaticQuery , graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Image from "../components/image"

import * as list from "../components/css/blogList.module.scss"

const BlogList = ({type}) => {
	const data = useStaticQuery(graphql `
	{
		site {
			id
			siteMetadata {
			  author
			  backgroundColor
			  description
			  domain
			  shortName
			  siteLanguage
			  title
			  url
			  ogpImage
			}
		  }

		allWpPost(sort: {fields: [date], order: DESC}) {
			edges {
				node {
					id
					title
					date(fromNow: false, locale: "jp", formatString: "yyyy-MM-dd'T'HH:mm:ss")
					excerpt
					slug
					featuredImage {
						node {
							localFile {
								url
								childImageSharp {
									fluid(maxWidth: 1024)  {
										...GatsbyImageSharpFluid
									}
									gatsbyImageData (
									width: 800
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
					categories {
						nodes {
							name
							slug
						}
					}
				}
			}
		}
	}
	`)

	const resultAll = function() {
		var ary = [];
		data.allWpPost.edges.forEach((node) =>
			ary.push(node)
		)
		ary.splice(4);
		return ary;
	}

	const resultCate = function() {
		var ary = [];
		data.allWpPost.edges.forEach((node) =>
			node.node.categories.nodes.forEach(function(cate) {
				if (cate.slug === type) {
					ary.push(node);
				}
			})
		)
		ary.splice(4);
		return ary;
	}

	var res;
	if (type === 'all') {
		res = resultAll()
	} else {
		res = resultCate()
	}


  return (
    	<section className={`${list.sectionArticles} ww-section-articles`}>
			<div className={`${list.headerWrapper} articles-header-wrapper`}>
				<div className={`${list.headerPosition} articles-header-position`}>
					<h2>ワンダウォールの記事</h2>
				</div>
			</div>

			<ul className={`${list.articlesPosition} articles-position`} itemScope="" itemType="http://schema.org/BreadcrumbList">
			{res.map(({ node }) => {
				let isFeaturedImage = (node.featuredImage) ? true : false
				let image = (isFeaturedImage) ? getImage(node.featuredImage.node.localFile.childImageSharp.gatsbyImageData) : data.site.siteMetadata.ogpImage
  
				return (
					<li className={`${list.articlesItem} articles-item`} itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem" key={`${node.id}`} >
					<Link to={`/articles/${node.slug}`} itemScope="" itemType="http://schema.org/Thing" itemProp="item">
						<div className={`${list.itemWrapper} articles-item-wrapper`} >
							<h4 className={`${list.itemHeader} article-header`} >{node.categories.nodes[0].name}</h4>
							<h3 className={`${list.itemCopy} article-copy`} itemProp="name">{node.title}</h3>
						</div>
					</Link>
					{isFeaturedImage && (
						<figure className={`${list.featuredImageFrame}`}>
						<GatsbyImage image={image} alt={node.title} />
						</figure>
					)}
					{!isFeaturedImage && (
						<figure className={`${list.featuredImageFrame}`}>
							<Image filename={`${image}`} alt={node.title} />
						</figure>
					)}
					</li>
				)
			})}
			</ul>
			<div className={`${list.articlesButtonPosition} articles-button-position`}>
				<Link to={`/articles/`} className="articles-button read-more">記事の一覧</Link>
            </div>
		</section>
	)
}

export default BlogList
