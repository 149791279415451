import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogList from "../components/blogList"
import Image from "../components/image"

import logo from "../images/wonderwall-logo-tricoroll.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Web/モバイルApp開発｜ITドキュメント制作" />
    <main id="ww-main" data-role="main">
      <section className="ww-section-billboard">
        <div className="billboard-position-1 billboard-copy-wrapper">
          <ul className="billboard-copyText-position">
            <li
              className="billboard-copy-item billboard-copy-position-1"
              itemScope
              itemType="http://schema.org/Corporation"
            >
              <h2 className="main-copy">
                <span className="line-1">先進の技術を誰の手にも</span>
                <span className="line-2">分かりやすく</span>
              </h2>
              <p className="body-copy">
                先端のIT技術を分かりやすく活用し、ビジネスやメディアを加速させる情報ツールを構築する。その
                “アイデアと実行” をワンダウォールにぜひお任せください。
              </p>
            </li>

            <li className="billboard-copy-item billboard-copy-position-2"></li>

            <li
              className="billboard-copy-item billboard-copy-position-3"
              style={{ display: `none` }}
            >
              <div className="link-with-smallArrow">
                詳しくはこちら
              </div>
            </li>

            <li className="billboard-copy-item billboard-copy-position-5">
              <div
                className="company-identifier tricoroll-color"
                itemProp="location"
                itemScope
                itemType="http://data-vocabulary.org/Organization"
              >
                <figure className="company-mark-svg">
                  <img src={logo} alt="Wonderwall-net Logo" />
                </figure>
                <figure className="company-logo-svg">
                  ワンダウォール｜ワンダーウォール
                </figure>
                <span itemProp="address" className="caption">
                  東京 日本橋
                </span>
              </div>
            </li>
          </ul>
        </div>

        <figure className="billboard-position-2 billboard-image billboard-image-composition">
          <Image filename="billboard-image@3x_20220414.jpg" />
        </figure>
        <figure className="billboard-position-3 billboard-image billboard-image-large-bg">
          <Image filename="billboard-image@3x_20220414.jpg" />
        </figure>
      </section>

      <section className="ww-section-services">
        <ul
          className="services-position clearfix"
          itemScope
          itemType="http://schema.org/BreadcrumbList"
        >
          <li
            className="service-item services-position-1"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link
              to="web-app-dev/"
              className="service-web-app-development"
              itemScope
              itemType="http://schema.org/Thing"
              itemProp="item"
            >
              <div className="service-item-wrapper">
                <div className="service-item-copy-wrapper">
                  <h3 className="service-header" itemProp="name">
                    Web / モバイルApp開発
                  </h3>
                  <h4 className="service-copy">
                    開発・運用のオーガナイズ／リサーチと設計／ビジュアルとコードの開発
                  </h4>
                </div>

                <div className="service-item-transparent-cover"></div>

                <figure className="service-main-image-web-app-development">
                  <Image filename="service-main-image-web-app-development-front@2x.png" />
                </figure>

                <div className="service-image-bg">
                <Image filename="service-main-image-web-app-development@2x.jpg" />
                </div>
              </div>
            </Link>
          </li>

          <li
            className="service-item services-position-2"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link
              to="IT-docs/"
              className="service-IT-documents"
              itemScope
              itemType="http://schema.org/Thing"
              itemProp="item"
            >
              <div className="service-item-wrapper">
                <div className="service-item-copy-wrapper">
                  <h3 className="service-header" itemProp="name">
                    ITドキュメント制作・編集
                  </h3>
                  <h4 className="service-copy">
                    ヒアリング／ライティング／オーサリング
                  </h4>
                </div>

                <div className="service-item-transparent-cover"></div>

                <figure className="service-main-image-IT-documents">
                  <Image filename="service-main-image-IT-documents-front@2x.png" />
                </figure>

                <div className="service-image-bg">
                  <Image filename="service-main-image-IT-documents@2x.jpg" />
                </div>
              </div>
            </Link>
          </li>

          <li
            className="service-item services-position-3"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/Product"
          >
            <a href="https://topo.st/" className="service-TopoCard">
              <div className="service-item-wrapper">
                  <h3 className="service-header" itemProp="name">
                    Topo Card（トポ・カード）
                  </h3>
                  <h4 className="service-copy">
                    場所にメモする位置情報アプリケーション
                  </h4>
                  <Image filename="topo-socialShareBillboard_v1_7.png" />
              </div>
              <div itemScope itemType="http://schema.org/MobileApplication">
                <meta
                  itemProp="applicationCategory"
                  content="ライフスタイル,ナビゲーション,LifeStyle,Navigation"
                />
                <meta
                  itemProp="availableOnDevice"
                  content="iPhone,iPod,iPad,AppleWatch"
                />
                <meta itemProp="countriesSupported" content="JP,US" />
                <meta itemProp="operatingSystem" content="iOS" />
                <meta
                  itemProp="downloadUrl"
                  content="https://itunes.apple.com/jp/app/topo-card-topokadode-chang/id811683158?mt=8"
                />
              </div>
            </a>
          </li>
        </ul>
      </section>

      <BlogList type="all" />
    </main>
  </Layout>
)

export default IndexPage
