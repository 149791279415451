import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allImageSharp {
          edges {
            node {
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              fixed {
                originalName
              }
            }
          }
        }
      }
    `}
  
    render={(data) => {
      var filename = ''
      if (props.filename) {
        filename = props.filename.split('/').slice(-1)[0]
      }

      const alt = (props.alt) ? props.alt : ""
      const classText = (props.className) ? props.className : ""

      const images = data.images.edges

      const imageObj = images.find(n => {
          return n.node.fixed.originalName.includes(filename);
      });
      var GImage = ""
      if (imageObj) {
          GImage = getImage(imageObj.node)
      }

      if (!GImage) { return null; }
      return (
          <GatsbyImage image={GImage} alt={alt} className={`${classText}`} />
      );
    }}
  />
  )
 export default Image